@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

details > summary {
  padding: 4px;

  /* width: 200px; */
  background-color: #eee;
  border: none;
  box-shadow: 1px 1px 2px #bbb;
  cursor: pointer;
}

details > p {
  background-color: #eee;
  padding: 4px;
  margin: 0;
  box-shadow: 1px 1px 2px #bbb;
}

body {
  font-size: 13px;
  font-family: arial, Arial, Helvetica;
}
:host,
:root {
  --rem: 16;
}

#masthead {
  text-align: center;
  height: 40%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;

}

#container {
  border: 2px solid #e9e9e9;
  margin-left: auto;
  margin-right: auto;
}

.h2-custom {
  font-weight: bold;
  font-family: 'Cuprum', Arial, sans-serif;
  color: #38a773;
  text-align: center;
}

.text-custom {
  font-size: small;
  text-align: justify;
  word-wrap: break-word; /* Ensures text wraps properly */
  padding-left: 10px; /* Adjust the value as needed */
  padding-right: 10px; /* Adjust the value as needed */
  margin: 0 auto; /* Centers the text block if it’s a block element */
  line-height: 150%; /* Sets line height to 150% */
}

.whatsnew-text {
  background-color: #3ba0cb;
  font-weight: bold;
  font-family: 'Cuprum', arial, Arial, sans-serif;
  font-size: 32px;
  color: #ffffff;
  text-align: center;
  font-size: x-large;
}

.background-color {
  background-color: #3ba0cb;
  text-align: center;
}

.whatnew-backgrounnd {
  background-color: #c6ecda;
}

.link-red {
  color: #d50000;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Source Sans', arial, Arial, Helvetica;
  font-size: 13px;
  line-height: normal;
  line-height: 150%;
}

hr {
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  unicode-bidi: isolate;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}

.expect-heading{
  font-weight: bold;
    font-family: 'Cuprum', arial, Arial, sans-serif;
    font-size: 32px;
    color: #38a773;
}

.paragraph-expect{
  font-weight: bold;
  font-family: 'Cuprum', arial, Arial, sans-serif;
  font-size: 24px;
  margin-top: 48px;
  color: #3ba0cb;
}

.attend-heading{
  font-weight: bold;
    font-family: 'Cuprum', arial, Arial, sans-serif;
    font-size: 32px;
    color: #38a773;
}

.attend-para{
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Cuprum', arial, Arial, sans-serif;
  margin-top: 0;
  color: #808080
}

.card-color{
  background-color: #3ba0cb;
}

.card-text{
  color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    font-family: 'Cuprum', arial, Arial, sans-serif;
    font-size: 24px;

    margin-bottom: 0;
}

.card-text-2{
  font-size: 20px;
  color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    font-family: 'Cuprum', arial, Arial, sans-serif;
}

.link-text-2{
  font-family: 'Source Sans', arial, Arial, Helvetica;
    font-size: 13px;
    color: #FFFFFF;
}

.event-container {
  width: 100%;
  height: 389px; /* Fixed height */
  background-image: url('https://t3.ftcdn.net/jpg/07/90/01/32/240_F_790013229_0NKjyOa9l47WK9ZW8pCHJ50dLolR979I.jpg'); /* Add your background image */
  background-size: cover; /* The image will fit inside without cropping */
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
}

.event-content {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6); /* Transparent black background */
  padding: 20px;
  border-radius: 10px;
  max-width: 700px; /* Constrain width */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.event-title {
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  margin: 0;
}

.event-subtitle {
  margin-top: 10px;
}

.event-location {
  font-size: 18px;
  margin: 5px 0;
}

.event-date {
  font-size: 18px;
  margin: 5px 0;
}

/* Highlight styles for year and date */
.highlight-year {
  background-color: rgba(255, 255, 255, 0.8); /* Light background for contrast */
  padding: 5px 10px;
  color: black; /* Make the text black for better visibility */
  border-radius: 5px;
  font-weight: bold;
}

.highlight-date {
  background-color: rgba(255, 204, 0, 0.8); /* Bright color for the date */
  padding: 5px 10px;
  color: black; /* Contrast text color */
  border-radius: 5px;
  font-weight: bold;
}

.event-link {
  font-size: 16px;
  color: white;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.event-link:hover {
  text-decoration: underline;
}